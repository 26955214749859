.knowledges{
  .knowledgesContent{
        min-height: $min-height;
        width: 100%;
        position: relative;
        overflow: hidden;
        display: grid;
        grid-template-columns: 38% 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: 
          "L E E"
          ;
        .languagesFrameworks, .experience{
            background: $color-2;
            border-radius: $borderRadius;
            padding: .8rem;
        }
        h3 {
           font-size:  1.4rem;
           margin-bottom: 1rem;
           font-family: $font-3;
           color: $color-1;
           text-transform: uppercase;
        } 
        .languagesFrameworks{
        grid-area: L;
       
        display: grid;
        grid-template-columns: 1fr;
       
        grid-template-areas: 
        "L"
        "F"
        "S";
        .languagesDisplay{
            grid-area: L;
        }
        .Competences{
          grid-area: F;
      }
        .hobbies{
            grid-area: S;
        
              ul {
                height: 80%;
                display: grid;
        
                li {
                  i {
                    position: absolute;
                    color: $color-5;
                  }
                  span {
                    position: absolute;
                    transform: translateX(2.2rem);
                  }
                }
              }
            
        }
        H3{
            margin-bottom: .3rem;
        }
        .years{
            font-size: .65rem;
            display: flex;
            justify-content: space-between;
            color: $white;

        span{
            width: 36%;
                &:nth-child(2){
                    text-align: center;
                    width: 26%;
                }
                &:nth-child(3){
                    text-align: right;
                    width: 44%
                }
                &:nth-child(2):before,&:nth-child(3):before{
                    content: '';
                    background: rgba(180,179,179,0.5);
                    position: absolute;
                    height: 350px;
                    width: 1px;
                    transform: translate(9px, 19px);
                }
              }
          }
          .lauguagesList{
              padding-top: .5rem;
              li{
                  color: $color-1;
              }
              .progressBar{
                  height: .4rem;
                  background: transparent;
                  border-radius: 50px;
                  margin-top: .2rem;
                  position: relative;

                  &::after{
                      content: '';
                      position: absolute;
                      background: $color-3;
                      height: .4rem;                     
                      border-radius: 50px;
                      width: 0;
                      animation: line 2s ease-out forwards;
                      @keyframes line {
                          to{
                              width: 100%;
                          }                         
                      }
                  }
              }
          }
        }
        .experience {
            grid-area: E;
            margin: 0 0 0 .5rem;
            display: grid;
            grid-template-columns: 1fr;

            grid-template-areas: 
              "1"
              "2"
              "3"
              "4"
              "5"
              "6"
              "7"
              "8";
            
            h3 {
              grid-area: 1;
            }
            h4 {
              font-size: 0.9rem;
              color: $color-6;
            }
            h5 {
              color: $color-3;
              padding: 0.2rem 0 0.2rem 1rem;
            }
            p {
              text-align: justify;
              padding: 0 1rem;
            }
            .exp-1 {
              grid-area: 2;
            }
            .exp-2 {
              grid-area: 3;
            }
            .exp-3 {
              grid-area: 4;
            }
            .exp-4 {
              grid-area: 5;
            }
            .exp-5 {
              grid-area: 6;
            }
            .exp-6 {
              grid-area: 7;
            }
            .exp-7 {
              grid-area: 8;
            }

          }


        }
      }
      @media screen and (max-width: 950px){
        .knowledgesContent {
          h3 {
            font-size: 1.2rem !important;
          }
          .languagesList {
            padding-top: .2rem !important;
          }
          p {
            font-size: .8rem;
          }

        }
      }
      @media screen and (max-width: 850px){
        .knowledgesContent {
          h4 {
            font-size: .9rem !important;
          }
          h5 {
            font-size: .6rem !important;
            padding: .2rem 1rem !important;
          }
        }
      }
      @media screen and (max-width: 500px){
        .knowledgesContent {
          display: grid !important;
          grid-template-columns: 1fr !important;
          grid-template-rows: 1fr 1fr 180px 180px !important;
          
          .languages {
            grid-area: 1 !important;
          }
          .experience {
            grid-area: 2 !important;
            margin-left: 0 !important;
          }

          
        }
      }
    