@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Josefin+Sans:ital,wght@1,100&family=Nova+Round&display=swap');

$font-3: 'Nova Round', cursive;
$font-1: 'Dosis', sans-serif;
$font-2: 'Josefin Sans', sans-serif;

$color-1: #2a1d52;
$color-2: #6C77F4;
$color-3: rgb(99, 164, 233);
$color-4: #d4edd2;
$color-5: #36a2ea;
$color-6: #2a1c55;
$white: #ddd;
$black: #333;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$min-height: 95vh;

@mixin verticalCenter {
	display:  flex;
	justify-content: center;
	align-items: center;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	margin:  1rem auto;
	max-width: 1200px;
	font-family: $font-1;
	font-size: 0.8em;
	color: $black;
}

button {
	cursor: pointer;
}

li {
	list-style-type: none;
}

a{
	text-decoration: none;
	color: $black;
	cursor: pointer;
}

a:visited{
	color: $black;
}
.home, .knowledges, .portfolio, .contact {
	display: flex;
  
	@media screen and (max-width: 850px){
	  display: block !important;
	}
  }
  .homeContent, .knowledgesContent, .portfolioContent, .contactContent {
	margin-left: .4rem;
  
	@media screen and (max-width: 850px){
	  margin-left: 0 !important;
	}
  }