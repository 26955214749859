.sidebar {
    background: $color-1;
    font-family: $font-1;
    height: 100%;
    min-height: $min-height;
    width: 264px;
    border-radius: $borderRadius;
    position:relative;
    display: grid;
    grid-template-columns: 1fr ;
    grid-template-rows: 250px 1fr 100px;
    grid-template-areas: 
    "1"
    "2"
    "3";

    .id {
        grid-area: 1;
        text-align: center;
        user-select: none;
        position: relative;

        .idContent {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;

            img{
                height: 150px;
                width: 150px;
                border-radius: 50%; 
                box-shadow: 0 0 0 4px $color-3, 0 0 0 8px $color-4, 0 0 0 12px $color-2; 
            }
            h3{
                font-size: 1.3rem;
                text-transform: uppercase ;
                letter-spacing: .2rem;
                margin-top: 1.5rem;
                color: $color-5;
            }
        }
    }
}
.navigation {
    grid-area: 2;
    padding:2rem 1rem;

    ul {
        height: 100%;
        display: grid;

        li{
            padding: 1rem 0 1rem .4rem;
            font-size: 1.3rem;
            position: relative;

            &:hover i {
                transform: translate(3px, 3px)
            };

            a{
                color: $color-5;
            }
            .navActive{
                color: $color-4;
            }
            i{
                position: absolute;
                font-size: 0.95rem ;
                transform: translateY(3px);
                transition: .2s ease;
            }
            span{
                margin-left: 2.7rem ;
            }
        }
    }
}
.socialNetwork{
    grid-area: 3;

    ul {
        display: flex;
        width: 264 px;
        padding: 0 14px;
        justify-content: space-between;

        li {
            font-size: 1.5rem;
            background-color: $color-5;
            color: $color-1;
            border-radius: 50%;
            padding: .5rem .8rem;
            transition: 0.5s;
            cursor: pointer;
            
            a:visites {
                color: $color-1;
            }
            &:nth-child(1){
                padding: .5rem 0.85rem;
            }
            &:nth-child(3){
                padding: .5rem 0.75rem;
            }
            &:hover{
                background: $color-4;
            }
        }
    }
    .signature{
        margin-top: 2rem;
        text-align: center;
        letter-spacing: .1rem;
        font-size: .7rem;
        p{
            color: $color-5;
            &:hover{
                color: $color-4;
            }
        }
    }
}


@media screen and (max-width: 850px){
    .sidebar {
      display: flex;
      min-height: 50px;
      margin-bottom: .2rem;
      width: 100%;
  
      .id, .socialNetwork {
        display: none;
      }
      .navigation {
        width: 100%;
        padding: .1rem 1rem;
  
        ul {
          display: flex;
          justify-content: space-between;
  
          span {
            margin-left: 1.5rem !important;
          }
        }
      }
    }
  }



  @media screen and (max-width: 500px){
    .navigation {  
        ul {
            justify-content: space-around !important;

            li {
                padding: 1rem 0 !important;
            }
            i{
                font-size: 1.4rem !important;
                transform: translateY(-3px) !important;
            }
            span{
                display: none;
            }
        }
    }        
  }